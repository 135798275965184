import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header';

function App() {
  return (
    <div className="App">
    <Header title="Secure Development Blog" />
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a 
        className="App-link"
        rel="me" 
        href="https://infosec.exchange/@siva"
        >
        </a>
    </div>
  );
}

export default App;
